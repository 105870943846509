/* main.css */
html, body {
    height: 100%; /* Ensure the full height of the page is always filled */
    margin: 0; /* Remove default margin */
  }
  
  #root, .App {
    display: flex;
    flex-direction: column;
    min-height: 100%; 
  }
  
  .content {
    flex: 1; /* This makes the content area flexible to take up all available space */
    display: flex;
    flex-direction: column; /* Stack content vertically */
  }
  
  footer {
    height: 50px; /* Set a fixed height for your footer */
    background-color: #333; /* Example background color */
    color: white; /* Example text color */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  h2{
    @apply text-center text-2xl font-bold mt-3 mb-6;
  }
  h3{
    @apply text-center text-xl font-semibold mt-2 mb-3;
  }
  h4{
    @apply text-lg font-semibold mt-2 mb-1 underline;
  }